import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }


    render() {

        return (
            <>
                <div className="container">
                    <div className="x-special-menu-entry -main-container">
                        <ul className="nav -nav">
                            <li className="nav-item">
                                <a
                                    href="/Lucky-fortune"
                                    className="nav-link -menu-birth-date animated fadeInUp"
                                    rel="nofollow noopener"
                                    data-animatable="fadeInUp"
                                    data-delay={200}
                                >
                                    <img
                                        alt="aexy club "
                                        className="-bg"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                    />
                                    <img
                                        alt="aexy club "
                                        className="-text-img img-fluid"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-wheel.png?v=1"
                                    />
                                </a>
                            </li>
                            <li className="nav-item">
                                <button
                                    className="nav-link -menu-social-share animated fadeInUp"
                                    data-toggle="modal"
                                    data-target="#loginModal"
                                    data-dismiss="modal"
                                    data-animatable="fadeInUp"
                                    data-delay={50}
                                >
                                    <img
                                        alt="aexy club "
                                        className="-bg"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                    />
                                    <img
                                        alt="aexy club "
                                        className="-text-img img-fluid"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-social-share.png?v=1"
                                    />
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className="nav-link -menu-bind-social animated fadeInUp"
                                    data-toggle="modal"
                                    data-target="#loginModal"
                                    data-dismiss="modal"
                                    data-animatable="fadeInUp"
                                    data-delay={100}
                                >
                                    <img
                                        alt="aexy club "
                                        className="-bg"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                    />
                                    <img
                                        alt="aexy club "
                                        className="-text-img img-fluid"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-bind-social.png?v=1"
                                    />
                                </button>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="/Affiliate"
                                    className="nav-link -menu-invitation animated fadeInUp"
                                    data-animatable="fadeInUp"
                                    data-delay={150}
                                >
                                    <img
                                        alt="aexy club "
                                        className="-bg"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                    />
                                    <img
                                        alt="aexy club "
                                        className="-text-img img-fluid"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-invitation.png?v=1"
                                    />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="https://lin.ee/ISe2kp4"
                                    className="nav-link -menu-birth-date animated fadeInUp"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    data-animatable="fadeInUp"
                                    data-delay={200}
                                >
                                    <img
                                        alt="aexy club "
                                        className="-bg"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                    />
                                    <img
                                        alt="aexy club "
                                        className="-text-img img-fluid"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-happy-birth-day.png?v=1"
                                    />
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link -menu-ranking animated fadeInUp"
                                    data-animatable="fadeInUp"
                                    data-delay={250}
                                >
                                    <img
                                        alt="aexy club "
                                        className="-bg"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                    />
                                    <img
                                        alt="aexy club "
                                        className="-text-img img-fluid"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-ranking.png?v=1"
                                    />
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <a
                                    href="https://www.24-hd.com/"
                                    className="nav-link -menu-movie animated fadeInUp"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    data-animatable="fadeInUp"
                                    data-delay={300}
                                >
                                    <img
                                        alt="aexy club "
                                        className="-bg"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                    />
                                    <img
                                        alt="aexy club "
                                        className="-text-img img-fluid"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-movie.png?v=1"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}
export default App;
